// Framework
import { Pipe, PipeTransform } from "@angular/core";
// Plugins
import { TranslateService } from "@ngx-translate/core";
// Enums
import { RedirectType } from "../enums/redirect-type.enum";

@Pipe({
    name: "redirectType"
})
export class RedirectTypePipe implements PipeTransform {
    constructor(private translate: TranslateService) { }

    transform(value: RedirectType): string {
        switch (value) {
            case RedirectType.blog:
                return this.translate.instant("redirect-type-pipe.blog");
            case RedirectType.shop:
                return this.translate.instant("redirect-type-pipe.shop");
            case RedirectType.website:
                return this.translate.instant("redirect-type-pipe.website");
            default:
                return null;
        }
    }
}
