// Framework
import { Injectable } from "@angular/core";
// Plugins
import { CookieService } from "ngx-cookie-service";
// Classes
import { Authentication } from "../classes/authentication.class";
// Services
import { BaseCookieService } from "./templates/base-cookie.service";

interface AppSettingsData {
    cookieVersion: string;
    currentAuthentication: Authentication;
}

@Injectable({
    providedIn: "root"
})
export class AuthenticationTokenService extends BaseCookieService<AppSettingsData> {

    constructor(cookieService: CookieService) {
        super("dsaapp-frontend-authentication-token", "1.0.0", cookieService);
    }

    saveAuthentication(authentication: Authentication): void {
        const appSettingsData = this.loadCookieData();
        appSettingsData.currentAuthentication = authentication;
        this.saveCookieData(appSettingsData);
    }

    /**
     * This method returns a default initialized AppSettingsData object.
     * @returns default initialized AppSettingsData object
     */
    protected getDefaultCookieData(): AppSettingsData {
        return {
            cookieVersion: this.cookieVersion,
            currentAuthentication: new Authentication(),
        };
    }
}
