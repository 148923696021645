// Framework
import { Pipe, PipeTransform } from "@angular/core";
// Enums
import { AllowedMessageCategories } from "../enums/allowed-message-categories";

@Pipe({
    name: "category"
})
export class CategoryPipe implements PipeTransform {

    transform(value: AllowedMessageCategories): any {
        switch (value) {
            case AllowedMessageCategories.news:
                return "category-pipe.news";
            case AllowedMessageCategories.shop:
                return "category-pipe.shop";
            case AllowedMessageCategories.magazine:
                return "category-pipe.magazine";
            case AllowedMessageCategories.wineCellar:
                return "category-pipe.wine-cellar";
            case AllowedMessageCategories.soldOutProduct:
                return "category-pipe.sold-out-product";
            default:
                return null;
        }
    }
}
