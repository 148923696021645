// Framework
import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
// Components
import { NotFoundComponent } from "./modules/share/components/not-found/not-found.component";
// Services
import { LoginGuardService } from "./route-guards/login-guard.service";

const routes: Routes = [
    {
        path: "",
        redirectTo: "organization/home-tile",
        pathMatch: "full"
    },
    {
        path: "session",
        loadChildren: () => import("./modules/login-backbone/login-backbone.module").then(m => m.LoginModule),
    },
    {
        path: "organization",
        loadChildren: () => import("./modules/organization-backbone/organization-backbone.module").then(m => m.OrganizationBackboneModule),
        canLoad: [LoginGuardService],
        canActivate: [LoginGuardService],
    },
    {
        path: "platform-admin",
        loadChildren: () => import("./modules/platform-admin-backbone/platform-admin-backbone.module").then(m => m.PlatformAdminBackboneModule),
        canLoad: [LoginGuardService],
        canActivate: [LoginGuardService],
    },
    {
        path: "profile",
        loadChildren: () => import("./modules/profile-backbone/profile-backbone.module").then(m => m.ProfileBackboneModule),
        canLoad: [LoginGuardService],
        canActivate: [LoginGuardService],
    },
    // not found component
    { path: "**", component: NotFoundComponent },
];

@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule]
})
export class AppRoutingModule { }
