// Framework
import { Injectable } from "@angular/core";
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent } from "@angular/common/http";
import { Observable } from "rxjs";
// Services
import { AuthenticationTokenService } from "./authentication-token.service";

@Injectable({
    providedIn: "root"
})
export class BaseInterceptor implements HttpInterceptor {

    constructor(private authenticationTokenService: AuthenticationTokenService) { }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        const authentication = this.authenticationTokenService.loadCookieData().currentAuthentication;

        if (authentication?.token !== null && authentication?.token !== "") {
            request = request.clone({
                headers: request.headers
                    .set("Cache-Control", "no-cache")
                    .set("Pragma", "no-cache")
                    .set("Expires", "Sat, 01 Jan 2000 00:00:00 GMT")
                    .set("Authorization", "Bearer " + authentication.token)
            });
        } else {
            request = request.clone({
                headers: request.headers
                    .set("Cache-Control", "no-cache")
                    .set("Pragma", "no-cache")
                    .set("Expires", "Sat, 01 Jan 2000 00:00:00 GMT")
            });
        }

        return next.handle(request);
    }
}
