// Framework
import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Observable, of } from "rxjs";
import { delay, map } from "rxjs/operators";
// Classes
import { FavoritePlace } from "@src/app/modules/share/classes/favorite-place.class";
import { Blog } from "@src/app/modules/share/classes/blog.class";
// Global
import { backendURL } from "@src/app/global";
// Interfaces
import { PaginationInterface } from "@src/app/modules/share/interfaces/pagination.interface";
// Services
import { DataApiService } from "@src/app/modules/share/services/templates/data-api.service";

@Injectable({
    providedIn: "root"
})
export class BlogApiService extends DataApiService<FavoritePlace> {
    private dummyData: Blog[] = [
        new Blog({ id: "1", headline: "Test Headline 1", category: { id: "1", name: "Food" } }),
        new Blog({ id: "2", headline: "Test Headline 2", category: { id: "1", name: "Food" } }),
        new Blog({ id: "3", headline: "Test Headline 3", category: { id: "2", name: "Wine" } }),
    ];

    constructor(protected http: HttpClient) {
        super(backendURL + "Blog/", http, FavoritePlace);
    }

    getAllBlogsByCategory(categoryId: string): Observable<Blog[]> {
        return this.http.get<PaginationInterface<Blog>>(this.url + "?categoryId=" + categoryId, this.requestOptions).pipe(map((res) => res.items));
        // return of(this.dummyData).pipe(delay(500));
    }
}
