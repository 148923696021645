// Framework
import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Observable, of } from "rxjs";
import { delay } from "rxjs/operators";
// Classes
import { Photo } from "../../classes/photo.class";
// Global
import { backendURL } from "@src/app/global";

@Injectable({
    providedIn: "root"
})
export class UploadFileApiService {
    constructor(protected http: HttpClient) { }

    uploadHomeTileImage(file: File): Observable<Photo> {
        const formData = new FormData();
        formData.append("image", file);

        const url = backendURL + "Image/UploadImage";
        return this.http.post<any>(url, formData);
        // return of(new Photo({
        //     id: "1",
        //     name: "Test photo",
        //     path: "https://picsum.photos/800/450",
        // })).pipe(delay(500));
    }
}
