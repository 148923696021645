// Framework
import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from "@angular/core";
import { Subscription, throwError } from "rxjs";
import { catchError, finalize } from "rxjs/operators";
import { FormBuilder, FormControl, FormGroup, Validators } from "@angular/forms";
// Classes
import { HomeTile } from "@src/app/modules/share/classes/home-tile.class";
import { Blog } from "@src/app/modules/share/classes/blog.class";
import { Product } from "@src/app/modules/share/classes/product.class";
// Enums
import { RedirectType } from "@src/app/modules/share/enums/redirect-type.enum";
// Interface
import { Lookup } from "@src/app/modules/share/interfaces/lookup.interface";
// Services
import { AlertService } from "@src/app/modules/share/services/alert.service";
import { UploadFileApiService } from "@src/app/modules/share/services/api/upload-file-api.service";
import { LookupApiService } from "@src/app/modules/share/services/api/lookup-api.service";
import { BlogApiService } from "@src/app/modules/share/services/api/blogs-api.service";
import { ProductApiService } from "@src/app/modules/share/services/api/product-api.service";

@Component({
    selector: "app-sections-editor",
    templateUrl: "./sections-editor.component.html",
    styleUrls: ["./sections-editor.component.scss"],
})
export class SectionsEditorComponent implements OnInit, OnDestroy {
    private subscription = new Subscription();
    @Input() resource: HomeTile = null;
    @Input() title: string;
    @Output() photoUploaded = new EventEmitter<{ homeTile: HomeTile }>();
    homeTileForm: FormGroup;
    redirectTypes = Object.values(RedirectType).filter((value) => typeof value === "number") as RedirectType[];
    blogs: Blog[] = [];
    blogCategories: Lookup[] = [];
    products: Product[] = [];
    productCategories: Lookup[] = [];
    redirectTypeEnum = RedirectType;
    fileUploaded = false;
    loading = false;

    get isAdTileControl(): FormControl {
        return this.homeTileForm.get("isAdTile") as FormControl;
    }

    get redirectTypeControl(): FormControl {
        return this.homeTileForm.get("redirectType") as FormControl;
    }

    get redirectIdControl(): FormControl {
        return this.homeTileForm.get("redirectId") as FormControl;
    }

    get redirectUrlControl(): FormControl {
        return this.homeTileForm.get("redirectUrl") as FormControl;
    }

    constructor(
        private alertService: AlertService,
        private uploadFileApiService: UploadFileApiService,
        private fb: FormBuilder,
        private blogApi: BlogApiService,
        private productApi: ProductApiService,
        private lookupApi: LookupApiService,
    ) {
        this.homeTileForm = this.fb.group({
            startDate: [null],
            endDate: [null],
            redirectType: [null],
            redirectId: [null],
            redirectUrl: [null],
            isAdTile: [null],
            isVisible: [null],
        });
    }

    ngOnInit(): void {
        this.homeTileForm.patchValue(this.resource);

        // Check the validators if the redirectType of the HomeTile is "website"
        if (this.resource?.redirectType === this.redirectTypeEnum.website) {
            this.redirectUrlControl.addValidators(Validators.required);
        } else {
            this.redirectUrlControl.removeValidators(Validators.required);
        }
        this.redirectUrlControl.updateValueAndValidity();
        this.homeTileForm.updateValueAndValidity();

        // Load the data
        this.loadData();
    }

    ngOnDestroy(): void {
        this.subscription.unsubscribe();
    }

    onFileSelected(event: any): void {
        const file: File = event.target.files[0];

        if (file != null) {
            this.fileUploaded = true;
            this.uploadPhoto(file);
        }
    }

    /**
     * Triggered when the redirect type is changed
     * @param event param fired by the change event
     */
    onRedirectTypeChanged(event: any): void {
        if (event.value === ("1: " + this.redirectTypeEnum.website)) {
            this.redirectUrlControl.addValidators(Validators.required);
        } else {
            this.redirectUrlControl.removeValidators(Validators.required);
        }
        this.redirectUrlControl.updateValueAndValidity();
    }

    /**
     * Triggered when the blog category select is changed
     * @param event param fired by the change event
     */
    onCategoryChanged(event: any): void {
        if (event.value !== "null" && event.value != null) {
            this.loadCategoryData(event.value);
        } else {
            this.blogs = [];
        }
    }

    /**
     * Triggered when the product category select is changed
     * @param event param fired by the change event
     */
    onProductCategoryChanged(event: any): void {
        if (event.value !== "null" && event.value != null) {
            this.loadProductData(event.value);
        } else {
            this.products = [];
        }
    }

    /**
     * sets the blog id from the select tag
     * @param event fired by the change event
     */
    onSelectedIdChanged(event: any): void {
        this.redirectIdControl.patchValue(event.value);
    }

    /**
     * This method loads all the product categories and products for the selection
     */
    private loadProductData(categoryId?: string): void {
        if (categoryId != null) {
            this.subscription.add(this.productApi.getAllProducts(categoryId)
                .subscribe(
                    (products) => {
                        this.products = products;
                    }
                )
            );
        }
    }

    /**
     * This method loads all the blog categories and blogs for the selection
     */
    private loadCategoryData(categoryId?: string): void {
        if (categoryId != null) {
            this.subscription.add(this.blogApi.getAllBlogsByCategory(categoryId)
                .subscribe(
                    (blogs) => {
                        this.blogs = blogs;
                    }
                )
            );
        }
    }

    private uploadPhoto(file: File): void {
        this.loading = true;
        this.subscription.add(this.uploadFileApiService
            .uploadHomeTileImage(file)
            .pipe(
                catchError((err) => {
                    this.alertService.error("usaago.alert.file-upload");
                    return throwError(() => err);
                }),
                finalize(() => (this.loading = false)),
            )
            .subscribe((photo) => {
                this.resource.image = photo;
                this.photoUploaded.emit({ homeTile: this.resource });
            })
        );
    }

    /**
     * Loads the blog and product category data
     */
    private loadData(): void {
        this.subscription.add(this.lookupApi.getLookupData("BlogCategory")
            .subscribe(
                (categories) => {
                    this.blogCategories = categories?.items;
                }
            )
        );
        this.subscription.add(this.lookupApi.getLookupData("ProductCategory")
            .subscribe(
                (categories) => {
                    this.productCategories = categories?.items;
                }
            )
        );
    }

}
