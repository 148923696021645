export class FavoritePlace {
    id: string = null;
    placeId?: string = null;
    name: string = null;
    lat: number = null;
    lng: number = null;
    blogId: string = null;
    websiteUrl: string = null;
    phone: string = null;

    constructor(placeShape?: Partial<FavoritePlace>) {
        if (placeShape != null) {
            if (placeShape.id != null) {
                this.id = placeShape.id;
            }

            if (placeShape.placeId != null) {
                this.placeId = placeShape.placeId;
            }

            if (placeShape.name != null) {
                this.name = placeShape.name;
            }

            if (placeShape.lat != null) {
                this.lat = placeShape.lat;
            }

            if (placeShape.lng != null) {
                this.lng = placeShape.lng;
            }

            if (placeShape.blogId != null) {
                this.blogId = placeShape.blogId;
            }

            if (placeShape.websiteUrl != null) {
                this.websiteUrl = placeShape.websiteUrl;
            }

            if (placeShape.phone != null) {
                this.phone = placeShape.phone;
            }
        }
    }
}
