export class Authentication {
    id = "";
    token = "";

    constructor(authenticationShape?: Partial<Authentication>) {
        if (authenticationShape != null) {
            if (authenticationShape.id != null) {
                this.id = authenticationShape.id;
            }

            if (authenticationShape.token != null) {
                this.token = authenticationShape.token;
            }
        }
    }
}
