// Framework
import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Observable, of } from "rxjs";
import { map } from "rxjs/operators";
// Classes
import { Authentication } from "@src/app/modules/share/classes/authentication.class";
import { Credentials } from "@src/app/modules/share/classes/credentials.class";
// Global
import { backendURL } from "@src/app/global";
// Services
import { DataApiService } from "@src/app/modules/share/services/templates/data-api.service";

@Injectable({
    providedIn: "root"
})
export class SessionApiService extends DataApiService<Authentication, Credentials> {

    constructor(protected http: HttpClient) {
        super(backendURL + "authentication/", http, Authentication);
    }

    getSession(): Observable<boolean> {
        return this.http.get(backendURL + "authentication/verify").pipe(
            map(() => true),
        );
        // return of(null);
    }

    createSession(loginObject: Credentials): Observable<Authentication> {
        return this.create(loginObject, "login");
        // return of(null);
    }

    /**
     * This method calls the backend and deletes the session.
     */
    deleteSession(): Observable<Authentication> {
        return this.create(null, "/logout/");
        // return of(null);
    }

    patchOrganization(id: string): Observable<void> {
        return this.http.patch<void>(this.url + "current-organization", { organizationId: id });
        // return of(null);
    }
}
