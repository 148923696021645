// Interfaces
import { Lookup } from "../interfaces/lookup.interface";

export class Blog {
    id: string = null;
    body: string = null;
    category: Lookup = null;
    teaserText: string = null;
    headline: string = null;
    imgUrl: string = null;

    constructor(blogShape?: Partial<Blog>) {
        if (blogShape != null) {
            if (blogShape.id != null) {
                this.id = blogShape.id;
            }

            if (blogShape.body != null) {
                this.body = blogShape.body;
            }

            if (blogShape.category != null) {
                this.category = blogShape.category;
            }

            if (blogShape.teaserText != null) {
                this.teaserText = blogShape.teaserText;
            }

            if (blogShape.headline != null) {
                this.headline = blogShape.headline;
            }

            if (blogShape.imgUrl != null) {
                this.imgUrl = blogShape.imgUrl;
            }
        }
    }
}
