// Framework
import { Injectable } from "@angular/core";
import { Observable, of } from "rxjs";
import { HttpClient } from "@angular/common/http";
// Interfaces
import { PaginationInterface } from "../../interfaces/pagination.interface";
import { Lookup } from "../../interfaces/lookup.interface";
// Global
import { backendURL } from "@src/app/global";
// Services
import { DataApiService } from "@src/app/modules/share/services/templates/data-api.service";

@Injectable({
    providedIn: "root"
})
export class LookupApiService extends DataApiService<PaginationInterface<Lookup>> {
    private dummyData: Lookup[] = [
        {
            id: "1",
            name: "test 1"
        },
        {
            id: "2",
            name: "test 2"
        },
        {
            id: "3",
            name: "test 3"
        },
    ];

    constructor(protected http: HttpClient) {
        super(backendURL + "Lookup/", http, null);
    }

    getLookupData(lookupType: "Country" | "Salutation" | "ProductCategory" | "Properties" | "PaymentMethods" | "BlogCategory"): Observable<PaginationInterface<{
        id: string;
        name: string;
    }>> {
        return this.http.get<PaginationInterface<Lookup>>(backendURL + "Lookup/" + lookupType, this.requestOptions);
        // return of({ items: this.dummyData, total: this.dummyData.length, limit: this.dummyData.length, page: 1 });
    }
}
