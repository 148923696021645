// Framework
import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { HttpClientModule, HTTP_INTERCEPTORS} from "@angular/common/http";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
// Plugins
import { NgMultiSelectDropDownModule } from "ng-multiselect-dropdown";
import { NgxPaginationModule } from "ngx-pagination";
// Project modules
import { TranslateModule } from "@ngx-translate/core";
import { ToastrModule } from "ngx-toastr";
import { ShareRoutingModule } from "./share-routing.module";
// Components
import { ShareComponent } from "./components/share/share.component";
import { NotFoundComponent } from "./components/not-found/not-found.component";
import { LoadingSpinnerComponent } from "./components/loading-spinner/loading-spinner.component";
import { SectionsEditorComponent } from "./components/sections-editor/sections-editor.component";
// Pipes
import { GenderPipe } from "./pipes/gender.pipe";
import { CategoryPipe } from "./pipes/category.pipe";
import { RedirectTypePipe } from "./pipes/redirect-type.pipe";
// Services
import { BaseInterceptor } from "./services/base-interceptor.service";

@NgModule({
    declarations: [
        ShareComponent,
        NotFoundComponent,
        GenderPipe,
        LoadingSpinnerComponent,
        CategoryPipe,
        SectionsEditorComponent,
        RedirectTypePipe,
    ],
    imports: [
        CommonModule,
        ShareRoutingModule,
        TranslateModule,
        HttpClientModule,
        FormsModule,
        ReactiveFormsModule,
        ToastrModule.forRoot({
            positionClass: "toast-bottom-right",
            maxOpened: 5,
            toastClass: "",
            iconClasses: {
                success: "alert alert--success",
                error: "alert alert--danger",
                info: "alert alert--info",
                warning: "alert alert--warning"
            },
            // individual
            closeButton: false,
            tapToDismiss: true,
            progressBar: true,
        }),
        NgMultiSelectDropDownModule,
        NgxPaginationModule,
    ],
    providers: [{ provide: HTTP_INTERCEPTORS, useClass: BaseInterceptor, multi: true }],
    exports: [
        HttpClientModule,
        TranslateModule,
        FormsModule,
        ReactiveFormsModule,
        GenderPipe,
        NgMultiSelectDropDownModule,
        NgxPaginationModule,
        LoadingSpinnerComponent,
        CategoryPipe,
        SectionsEditorComponent,
        RedirectTypePipe,
    ]
})
export class ShareModule { }
