// Framework
import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
// Classes
import { Options } from "../../classes/options.class";
import { UpdateRequest } from "../../classes/update-request.class";


export abstract class DataApiService<T, G = T> {
    protected requestOptions = {
        headers: new HttpHeaders().append("Content-Type", "application/json"),
        withCredentials: true
    };

    constructor(protected url: string, protected http: HttpClient, private type: new (object?: Partial<T>) => T) { }

    /**
     * This method calls the backend to return all resources.
     * @param urlExtension Additional url information that is patched after "this.url"
     * @returns an Observable of an object containing the data.
     */
    protected getAll(urlExtension = ""): Observable<T[]> {
        return this.http.get<T[]>(this.url + urlExtension, this.requestOptions)
            .pipe(
                map((res) => {
                    return res.map((value) => new this.type(value));
                }),
            );
    }

    /**
     * This method calls the backend to return the resources with pagination.
     * @param itemsPerPage number of resources to retrieve.
     * @param page page number.
     * @returns an Observable of an object containing the data and the total of resources.
     */
    getAllWithPagination(
        itemsPerPage: number = null,
        page: number = null,
        urlExtension = "",
        additionalParams: { name: string, value: any }[] = [],
    ): Observable<{ data: T[], total: number }> {
        let queryParam = new HttpParams();

        if (itemsPerPage != null) {
            queryParam = queryParam.append("items", itemsPerPage.toString());
        }
        if (page != null) {
            queryParam = queryParam.append("page", page.toString());
        }
        additionalParams.forEach((param) => {
            queryParam = queryParam.append(param.name, param.value.toString());
        });

        return this.http.get<{ data: T[], total: number }>(this.url + urlExtension + `?${queryParam.toString()}`)
            .pipe(
                map((res) => {
                    return {
                        data: res?.data?.map((value) => new this.type(value)),
                        total: res?.total,
                    };
                }),
            );
    }

    /**
     * This method calls the backend to create the resource.
     * @param resource the resource that should be created on the server.
     * @param urlExtension Additional url information that is patched after "this.url"
     * @returns an Observable of an object containing the data.
     */
    protected create(resource: G, urlExtension = ""): Observable<T> {
        return this.http.post<T>(this.url + urlExtension, resource, this.requestOptions)
            .pipe(
                map((res) => {
                    return new this.type(res);
                })
            );
    }

    /**
     * This method calls the backend to receive a resource with the given id.
     * @param resourceId the id of the resource that should be fetched from the server.
     * @param urlExtension Additional url information that is patched after "this.url"
     * @returns an Observable of an object containing the data.
     */
    protected getOne(resourceId: string, urlExtension = ""): Observable<T> {
        return this.http.get<T>(this.url + urlExtension + resourceId, this.requestOptions)
            .pipe(
                map((res) => {
                    return new this.type(res);
                })
            );
    }

    /**
     * This method calls the backend to put a resource with the given id.
     * @param resourceId the id of the resource that should be updated on the server.
     * @param resource the resource that should be updated on the server.
     * @returns an Observable of an object containing the data.
     */
    protected put(resourceId: string, resource: T): Observable<T> {
        return this.http.put<T>(this.url + resourceId, resource, this.requestOptions)
            .pipe(
                map((res) => {
                    return new this.type(res);
                })
            );
    }

    /**
     * This method calls the backend to patch a resource with the given id.
     * @param resourceId the id of the resource that should be patched on the server.
     * @param resource the resource that should be patched on the server.
     * @returns an Observable of an object containing the data.
     */
    protected patch(resourceId: string, resource: Partial<T>, params?: any): Observable<T> {
        const options = new Options(params);
        const data = new UpdateRequest<Partial<T>>(resource, Object.keys(resource));
        return this.http.patch<T>(this.url + resourceId, data, options)
            .pipe(
                map((res) => {
                    return new this.type(res);
                })
            );
    }

    /**
     * This method calls the backend to delete a resource with the given id.
     * @param resourceId the id of the resource that should be deleted on the server.
     */
    protected deleteOne(resourceId: string): Observable<void> {
        return this.http.delete<void>(this.url + resourceId, this.requestOptions);
    }
}
