export class Product {
    id: string = null;
    name: string = null;
    imageUrl: string[] = [];
    purchasePrice: number = null;
    purchaseUnit: string = null;
    referencePrice: number = null;
    referenceUnit: string = null;
    productNumber: string = null;
    unitName: string = null;
    description: string = null;
    isFavored = false;

    constructor(productShape?: Partial<Product>) {
        if (productShape != null) {
            if (productShape.id != null) {
                this.id = productShape.id;
            }

            if (productShape.name != null) {
                this.name = productShape.name;
            }

            if (productShape.imageUrl != null) {
                this.imageUrl = productShape.imageUrl;
            }

            if (productShape.purchasePrice != null) {
                this.purchasePrice = productShape.purchasePrice;
            }

            if (productShape.purchaseUnit != null) {
                this.purchaseUnit = productShape.purchaseUnit;
            }

            if (productShape.referencePrice != null) {
                this.referencePrice = productShape.referencePrice;
            }

            if (productShape.referenceUnit != null) {
                this.referenceUnit = productShape.referenceUnit;
            }

            if (productShape.productNumber != null) {
                this.productNumber = productShape.productNumber;
            }

            if (productShape.unitName != null) {
                this.unitName = productShape.unitName;
            }

            if (productShape.description != null) {
                this.description = productShape.description;
            }

            if (productShape.isFavored != null) {
                this.isFavored = productShape.isFavored;
            }
        }
    }
}
