<div class="container mb-3">
    <div class="row">
        <div class="col-12">
            <!-- Headline -->
            <h4 class="m-0">{{ title }}</h4>
            <div class="d-flex flex-column">
                <!-- File Upload -->
                <input type="file" class="file-input py-3" (change)="onFileSelected($event)" #fileUpload />
                <app-loading-spinner *ngIf="loading"></app-loading-spinner>
                <div>
                    <!-- Image Preview -->
                    <div class="imagePreview" *ngIf="resource?.image?.path != null && resource?.image?.path !== ''">
                        <img height="100" [src]="resource.image.path" />
                    </div>
                </div>
            </div>
        </div>
        <!-- Form -->
        <form [formGroup]="homeTileForm" class="col-12" *ngIf="isAdTileControl.value === true">
            <div class="row">
                <!-- Start Date -->
                <div class="col-6 form-group">
                    <label [for]="resource?.id + '-start-date'" class="form-control-label">
                        {{ 'home-tile.start-date' | translate }}
                    </label>
                    <input
                        class="form-control"
                        type="date"
                        [id]="resource?.id + '-start-date'"
                        formControlName="startDate"
                        placeholder="DD / MM / YYYY"
                    />
                </div>
                <!-- End Date -->
                <div class="col-6 form-group">
                    <label [for]="resource?.id + '-end-date'" class="form-control-label">
                        {{ 'home-tile.end-date' | translate }}
                    </label>
                    <input
                        class="form-control"
                        type="date"
                        [id]="resource?.id + '-end-date'"
                        formControlName="endDate"
                        placeholder="DD / MM / YYYY"
                    />
                </div>
                <!-- Select Type -->
                <div class="col-12 col-xl-6 form-group">
                    <label [for]="resource?.id + '-home-tile-redirect-type'" class="form-control-label">{{ 'home-tile.redirect-type' | translate }}</label>
                    <select formControlName="redirectType" class="form-control" [id]="resource?.id + '-home-tile-redirect-type'" (change)="onRedirectTypeChanged($event.target)">
                        <option [ngValue]="null">{{ 'usaago.form.select' | translate }}</option>
                        <ng-container *ngFor="let redirectType of redirectTypes">
                            <option [ngValue]="redirectType">{{ redirectType | redirectType }}</option>
                        </ng-container>
                    </select>
                </div>
                <!-- Select Type Web-->
                <div *ngIf="redirectTypeControl.value === redirectTypeEnum.website" class="col-12 mb-3">
                    <label [for]="resource?.id + '-start-date'" class="form-control-label">
                        {{ 'home-tile.url' | translate }}
                    </label>
                    <input
                        class="form-control"
                        type="text"
                        [id]="resource?.id + '-start-date'"
                        formControlName="redirectUrl"
                    />
                    <div class="invalid-feedback">
                        {{ 'sections-editor.invalid-feedback-web' | translate }}
                    </div>
                </div>
                <!-- Select Type Blog -->
                <div *ngIf="redirectTypeControl.value === redirectTypeEnum.blog" class="col-12 mb-3">
                    <div class="row">
                        <div class="col-12 col-md-8">
                            <select class="custom-select form-group" (change)="onCategoryChanged($event.target)">
                                <option [ngValue]="null">{{ "home-tile.magazine-category" | translate }}</option>
                                <option *ngFor="let category of blogCategories" [value]="category?.id">
                                    {{ category?.name }}
                                </option>
                            </select>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-12 col-md-8" *ngIf="blogs.length > 0">
                            <select class="custom-select form-group" (change)="onSelectedIdChanged($event.target)">
                                <option [ngValue]="null">{{ "home-tile.magazine" | translate }}</option>
                                <option *ngFor="let blog of blogs" [value]="blog?.id">
                                    {{ blog?.headline }}
                                </option>
                            </select>
                        </div>
                    </div>
                </div>
                <!-- Select Type Shop -->
                <div *ngIf="redirectTypeControl.value === redirectTypeEnum.shop" class="col-12 mb-3">
                    <div class="row">
                        <div class="col-12 col-md-8">
                            <select class="custom-select form-group" (change)="onProductCategoryChanged($event.target)">
                                <option [ngValue]="null">{{ "home-tile.shop-category" | translate }}</option>
                                <option *ngFor="let category of productCategories" [value]="category?.id">
                                    {{ category?.name }}
                                </option>
                            </select>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-12 col-md-8" *ngIf="products.length > 0">
                            <select class="custom-select form-group" (change)="onSelectedIdChanged($event.target)">
                                <option [ngValue]="null">{{ "home-tile.product" | translate }}</option>
                                <option *ngFor="let product of products" [value]="product?.id">
                                    {{ product?.name }}
                                </option>
                            </select>
                        </div>
                    </div>
                </div>
                <!-- Deactivate Button -->
                <div class="col-12 mb-2">
                    <div class="form-check">
                        <input class="form-check-input" type="checkbox" formControlName="isVisible" [id]="resource?.id + '-sections-editor-visibility'">
                        <label class="form-check-label" [for]="resource?.id + '-sections-editor-visibility'">
                            {{ 'sections-editor.visibility' | translate }}
                        </label>
                    </div>
                </div>
            </div>
        </form>
    </div>
</div>
