// Framework
import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Observable, of } from "rxjs";
import { delay, map } from "rxjs/operators";
// Classes
import { Product } from "@src/app/modules/share/classes/product.class";
// Global
import { backendURL } from "@src/app/global";
// Interfaces
import { PaginationInterface } from "@src/app/modules/share/interfaces/pagination.interface";
// Services
import { DataApiService } from "@src/app/modules/share/services/templates/data-api.service";

@Injectable({
    providedIn: "root"
})

export class ProductApiService extends DataApiService<Product> {
    private dummyData: Product[] = [
        new Product({
            id: "1",
            imageUrl: ["https://picsum.photos/800/450"],
            name: "Name of Product",
            purchasePrice: 23.78,
            purchaseUnit: "0,75",
            unitName: "Liter",
            referencePrice: 34.23,
            referenceUnit: "1",
        }),
        new Product({
            id: "2",
            imageUrl: ["https://picsum.photos/800/450"],
            name: "Name of Product",
            purchasePrice: 23.78,
            purchaseUnit: "0,75",
            unitName: "Liter",
            referencePrice: 34.23,
            referenceUnit: "1",
        }),
        new Product({
            id: "3",
            imageUrl: ["https://picsum.photos/800/450"],
            name: "Name of Product",
            purchasePrice: 23.78,
            purchaseUnit: "0,75",
            unitName: "Liter",
            referencePrice: 34.23,
            referenceUnit: "1",
        }),
        new Product({
            id: "4",
            imageUrl: ["https://picsum.photos/800/450"],
            name: "Name of Product",
            purchasePrice: 23.78,
            purchaseUnit: "0,75",
            unitName: "Liter",
            referencePrice: 34.23,
            referenceUnit: "1",
        }),
        new Product({
            id: "5",
            imageUrl: ["https://picsum.photos/800/450"],
            name: "Name of Product",
            purchasePrice: 23.78,
            purchaseUnit: "0,75",
            unitName: "Liter",
            referencePrice: 34.23,
            referenceUnit: "1",
        }),
    ];

    constructor(protected http: HttpClient) {
        super(backendURL + "Product/", http, Product);
    }

    getAllProducts(categoryId: string): Observable<Product[]> {
        return this.http.get<PaginationInterface<Product>>(
            this.url + "ProductListing/" + categoryId,
            this.requestOptions,
        ).pipe(map((res) => res.items));
        // return of(this.dummyData).pipe(delay(500));
    }

}
